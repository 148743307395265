import React from "react";
import "./HomeStyles.css";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { Input } from "@mantine/core";
import { FiMenu, FiX } from "react-icons/fi"; // Importing icons

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const [activeTab, setActiveTab] = useState("first");

  const [isOpen1, setIsOpen1] = useState(false);

  const toggleAnswer1 = () => {
    setIsOpen1(!isOpen1);
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const toggleAnswer2 = () => {
    setIsOpen2(!isOpen2);
  };

  const [isOpen3, setIsOpen3] = useState(false);

  const toggleAnswer3 = () => {
    setIsOpen3(!isOpen3);
  };

  //--------------------------------circle scroll-----------------------//

  // State to manage which dot is active
  const [activeDot, setActiveDot] = useState(1);

  // Function to handle dot clicks
  const handleDotClick = (dotNumber) => {
    setActiveDot(dotNumber);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="flex flex-col justify-between min-h-screen bg-white font-suse">
        {/* Top section */}
        <div className="flex justify-between px-2 py-4 md:px-8 md:py-8">
          <div>
            <img
              alt="leadsify-logo"
              src="images/logo.png"
              className="h-auto w-28 sm:w-48 hover:cursor-pointer"
            />
          </div>

          {/* Hamburger menu button for small screens */}
          <div className="flex items-center md:hidden">
            <button
              onClick={toggleMenu}
              className="text-[#424530] focus:outline-none"
            >
              {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
            </button>
          </div>

          {/* Links - visible on large screens */}
          <div className="flex-row hidden space-x-4 md:flex sm:space-x-6">
            <p className="text-sm font-semibold text-white sm:text-lg md:text-xl hover:cursor-pointer">
              <Link to="/" className="text-[#424530]">
                Home
              </Link>
            </p>
            <p className="text-sm font-semibold text-white sm:text-lg md:text-xl hover:cursor-pointer">
              <Link to="/policy" className="text-[#424530]">
                Policy
              </Link>
            </p>
            <p className="text-sm font-semibold text-white sm:text-lg md:text-xl hover:cursor-pointer">
              <Link to="/contact" className="text-[#424530]">
                Contact Us
              </Link>
            </p>
            <p className="text-sm font-semibold text-white sm:text-lg md:text-xl hover:cursor-pointer">
              <Link
                to="https://www.dev-portal.latsuccess.com/auth/login"
                className="text-[#424530]"
              >
                Log in
              </Link>
            </p>
          </div>
        </div>

        {/* Mobile Menu - Smooth transition for visibility */}
        <div
          className={`md:hidden flex flex-col items-center space-y-4 transition-all duration-500 ease-in-out overflow-hidden ${
            isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <p className="text-sm font-semibold text-[#424530] hover:cursor-pointer">
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
          </p>
          <p className="text-sm font-semibold text-[#424530] hover:cursor-pointer">
            <Link to="/policy" onClick={toggleMenu}>
              Policy
            </Link>
          </p>
          <p className="text-sm font-semibold text-[#424530] hover:cursor-pointer">
            <Link to="/" onClick={toggleMenu}>
              Contact Us
            </Link>
          </p>
          <p className="text-sm font-semibold text-[#424530] hover:cursor-pointer">
            <Link
              to="https://www.dev-portal.latsuccess.com/auth/login"
              onClick={toggleMenu}
            >
              Log in
            </Link>
          </p>
        </div>

        {/* Center section */}
        <div className="relative flex items-center justify-center flex-grow section1-background">
          <div className="absolute inset-0 bg-white bg-opacity-80"></div>
          {/* Semi-transparent [#424530] overlay */}
          <div className="relative z-20 py-4 text-center sm:py-8">
            <p className="font-bold text-[#424530] text-6xl sm:text-5xl md:text-7xl tracking-wide leading-tight">
              Boost Your Success with LAT
            </p>

            <p className="mt-2 text-3xl text-gray-500 md:text-4xl sm:mt-4">
              Top Leads, Easy Appointments, Expert Training!
            </p>
          </div>
        </div>

        {/* Bottom section */}
        <div className="flex justify-between px-4 py-8 sm:px-8 sm:py-16">
          <div className="flex flex-row space-x-2">
            <img alt="box" src="images/box.png" className="w-6 h-auto sm:w-8" />
            <p className="text-base text-white sm:text-lg md:text-xl">
              Automated leads directly integrated into your systems.
            </p>
          </div>
          <div>
            <p className="text-base text-white underline sm:text-lg md:text-xl hover:cursor-pointer">
              Get started
            </p>
          </div>
        </div>
      </div>

      {/* second section */}
      <div className="py-10 bg-white font-suse">
        <div className="flex flex-col px-4 space-y-8 sm:px-8 md:px-16 lg:px-20">
          <div>
            <p className="text-[#696969] text-lg md:text-xl">FEATURES</p>
          </div>

          <div className="flex flex-row justify-between">
            <div>
              <p className="font-semibold text-[#424530] text-6xl md:text-6xl lg:text-7xl">
                Get Leads that Help <br />
                <p className="block mt-2 sm:mt-5">You Grow</p>{" "}
              </p>
            </div>
          </div>
        </div>

        {/* circular divs */}

        <div className="flex flex-col items-center justify-center py-16 space-y-8 lg:py-32">
          {/* First Row */}
          <div className="flex flex-col items-center justify-center w-full space-y-8 lg:flex-row lg:space-y-0 lg:space-x-8">
            <div className="w-full h-auto md:h-[40vh] max-w-lg flex flex-col space-y-6 border-2 border-[#424530] bg-gradient-to-b from-[#fff8f2] to-[#ffdbb5] rounded-3xl shadow-lg justify-center items-center p-8 lg:p-12 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <p className="text-2xl md:text-4xl font-bold text-[#424530] text-center leading-tight">
                Elevate Your Financial Professional Career
              </p>
              <p className="text-[#4d4d4d] text-xl md:text-lg lg:text-2xl text-center leading-relaxed">
                Achieve new heights with LAT, the all-in-one platform for every
                stage of your financial professional journey.
              </p>
            </div>

            <div className="w-full max-w-lg h-auto md:h-[40vh] flex flex-col space-y-6 border-2 border-[#424530] bg-gradient-to-b from-[#fff8f2] to-[#ffdbb5] rounded-3xl shadow-lg justify-center items-center p-8 lg:p-12 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <p className="text-2xl md:text-4xl font-bold text-[#424530] text-center leading-tight">
                High-Quality Leads
              </p>
              <p className="text-[#4d4d4d] text-xl md:text-lg lg:text-2xl text-center leading-relaxed">
                Generate top-tier leads and easily convert them into clients
                with LAT.
              </p>
            </div>

            <div className="w-full max-w-lg h-auto md:h-[40vh] flex flex-col space-y-6 border-2 border-[#424530] bg-gradient-to-b from-[#fff8f2] to-[#ffdbb5] rounded-3xl shadow-lg justify-center items-center p-8 lg:p-12 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <p className="text-2xl md:text-4xl font-bold text-[#424530] text-center leading-tight">
                Effortless Appointment Setting
              </p>
              <p className="text-[#4d4d4d] text-xl md:text-lg lg:text-2xl text-center leading-relaxed">
                Rely on LAT to fill your calendar with valuable appointments.
              </p>
            </div>
          </div>

          {/* Second Row */}
          <div className="flex flex-col items-center justify-center w-full pt-12 space-y-8 lg:flex-row lg:space-y-0 lg:space-x-8">
            <div className="w-full max-w-lg h-auto md:h-[40vh] flex flex-col space-y-6 border-2 border-[#424530] bg-gradient-to-b from-[#fff8f2] to-[#ffdbb5] rounded-3xl shadow-lg justify-center items-center p-8 lg:p-12 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <p className="text-2xl md:text-4xl font-bold text-[#424530] text-center leading-tight">
                Top-Notch Training
              </p>
              <p className="text-[#4d4d4d] text-xl md:text-lg lg:text-2xl text-center leading-relaxed">
                Access targeted training to excel in every aspect of financial
                professional sales.
              </p>
            </div>

            <div className="w-full max-w-lg h-auto md:h-[40vh] flex flex-col space-y-6 border-2 border-[#424530] bg-gradient-to-b from-[#fff8f2] to-[#ffdbb5] rounded-3xl shadow-lg justify-center items-center p-8 lg:p-12 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <p className="text-2xl md:text-4xl font-bold text-[#424530] text-center leading-tight">
                Maximized Conversions
              </p>
              <p className="text-[#4d4d4d] text-xl md:text-lg lg:text-2xl text-center leading-relaxed">
                Enjoy superior conversion rates with LAT’s high-quality leads.
              </p>
            </div>

            <div className="w-full max-w-lg h-auto md:h-[40vh] flex flex-col space-y-6 border-2 border-[#424530] bg-gradient-to-b from-[#fff8f2] to-[#ffdbb5] rounded-3xl shadow-lg justify-center items-center p-8 lg:p-12 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <p className="text-2xl md:text-4xl font-bold text-[#424530] text-center leading-tight">
                Your Success Partner
              </p>
              <p className="text-[#4d4d4d] text-xl md:text-lg lg:text-2xl text-center leading-relaxed">
                LAT supports you with lead generation, appointment setting, and
                expert training for lasting success.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col h-auto bg-black font-suse">
        <div className="flex justify-center mt-8">
          <div className="flex flex-row p-4 space-x-4 sm:p-10 md:p-14 lg:p-20">
            <span
              className={`w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 transform rounded-full hover:cursor-pointer ${
                activeDot === 1 ? "bg-white" : "bg-[#a2a2a2]"
              } shadow-effect`}
              onClick={() => handleDotClick(1)}
            ></span>
            {/* <span
              className={`w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 transform rounded-full hover:cursor-pointer ${
                activeDot === 2 ? "bg-white" : "bg-[#a2a2a2]"
              } shadow-effect`}
              onClick={() => handleDotClick(2)}
            ></span> */}
          </div>
        </div>

        <div className="flex-1">
          <CSSTransition
            in={activeDot === 1}
            timeout={300}
            classNames="dot"
            unmountOnExit
          >
            <div className="flex-1">
              <div className="flex flex-col justify-between h-auto p-4 space-y-10 lg:space-y-28 sm:p-10 md:p-14 lg:p-20">
                <div className="flex flex-col space-y-6">
                  <p className="text-2xl font-semibold text-right text-white sm:text-2xl md:text-4xl lg:text-5xl">
                    Appointment Settings with LAT
                  </p>
                  <div className="flex flex-row items-center justify-end space-x-4">
                    <p className="text-lg sm:text-lg md:text-xl lg:text-2xl text-[#a2a2a2]">
                      Get your appointments booked by the best in the industry.
                      Experience high show ratios and ensure your calendar is
                      filled with meaningful opportunities.
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-4xl font-semibold text-white sm:text-6xl md:text-7xl lg:text-8xl">
                    Next
                  </p>
                </div>
                <div>
                  <p className="text-lg sm:text-lg md:text-3xl lg:text-4xl text-center md:text-right text-[#a2a2a2] ml-0 sm:ml-32 md:ml-56">
                    Unlock your potential with LAT Training—professional
                    training crafted for selling financial professional. Master
                    industry best practices, licensing, and closing techniques.
                  </p>
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>

      {/* fifth section */}
      <div className="py-4 bg-black md:py-20 font-suse">
        {/* <div className="relative flex flex-col px-6 mb-16 space-y-8 md:px-12 md:mb-32 section3-background">
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
          <div className="relative z-10 px-6 space-y-6 md:px-12">
            <div>
              <p className="text-[#b2b2b2] text-3xl md:text-5xl">Pricing</p>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-4">
                <div>
                  <p className="text-[#b2b2b2] text-xl md:text-4xl">
                    Your next client is waiting for you
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="flex items-center justify-center">
          <p className="text-lg text-white underline md:text-xl">
            CURRENTLY OPEN PACKAGES
          </p>
        </div> */}

        <div className="flex flex-col items-center justify-center">
          <div className="h-[40rem] md:h-[42rem] w-[20rem] md:w-[27rem] border-2 border-white rounded-3xl px-6 py-8 md:px-8 md:py-12">
            <div className="flex flex-col justify-between h-full">
              <div>
                <div className="flex flex-col space-y-4 md:space-y-6">
                  <p className="text-3xl text-white md:text-4xl">LAT Premium</p>
                  <p className="text-lg md:text-2xl text-[#b2b2b2]">
                    Platform for financial professionals who want to maximize
                    efficiency and focus on closing deals.
                  </p>
                </div>
                <div className="py-3">
                  {/* <p className="text-4xl text-white md:text-5xl">
                    $---/
                    <span className="ml-2 text-base md:text-lg text-[#808080]">
                      month
                    </span>
                  </p> */}
                </div>
                <div className="flex flex-col space-y-4 md:space-y-6">
                  <div className="flex flex-row items-center space-x-2">
                    <span className="text-lg text-white md:text-xl">•</span>
                    <p className="text-lg md:text-xl text-[#b2b2b2]">
                      High-quality leads/month
                    </p>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <span className="text-lg text-white md:text-xl">•</span>
                    <p className="text-lg md:text-xl text-[#b2b2b2]">
                      Personalized emails sent on your behalf
                    </p>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <span className="text-lg text-white md:text-xl">•</span>
                    <p className="text-lg md:text-xl text-[#b2b2b2]">
                      Zero commission fees
                    </p>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <span className="text-lg text-white md:text-xl">•</span>
                    <p className="text-lg md:text-xl text-[#b2b2b2]">
                      Calls and meetings scheduled for you
                    </p>
                  </div>
            
                  <div className="flex flex-row items-center space-x-2">
                    <span className="text-lg text-white md:text-xl">•</span>
                    <p className="text-lg md:text-xl text-[#b2b2b2]">
                      Detailed, up-to-date reporting
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <p className="text-lg text-white underline md:text-xl hover:cursor-pointer">
                  Get Started
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Eighth Section */}
      <div className="bg-white py-28 font-suse">
        <div className="relative z-10 flex flex-col space-y-10">
          <div className="px-4 md:px-8 lg:px-12 space-y-10 border-b-2 border-[#5a5a5a] pb-12 md:pb-20 lg:pb-28">
            <div>
              <p className="text-[#5a5a5a] text-base sm:text-lg md:text-xl">
                Frequent questions
              </p>
            </div>

            <div className="flex flex-col justify-between md:flex-row">
              <div>
                <p className="font-semibold text-[#424530] text-3xl sm:text-5xl md:text-6xl lg:text-7xl">
                  You have questions and we
                  <br />
                  <span className="block mt-2 lg:mt-5">have answers</span>
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col pb-10 space-y-2 md:pb-16 lg:pb-20">
            <div className="w-full border-b-2 border-[#5a5a5a] ">
              <div
                className="flex items-center justify-between p-4 cursor-pointer md:p-6 rounded-3xl"
                onClick={toggleAnswer1}
              >
                <p className="text-lg sm:text-2xl md:text-2xl lg:text-3xl text-[#424530] font-josefin">
                  Is LAT the right fit for me?
                </p>
                <FontAwesomeIcon
                  icon={isOpen1 ? faXmark : faPlus}
                  color="[#424530]"
                  className="ml-2 text-2xl md:text-2xl lg:text-2xl"
                />
              </div>
              <div
                className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                  isOpen1 ? "max-h-40" : "max-h-0"
                }`}
              >
                <div className="p-4 md:p-6 mt-2 text-base sm:text-2xl md:text-2xl lg:text-2xl text-[#535353] rounded-3xl font-josefin">
                  LAT is ideal for financial professional professionals, whether
                  you’re part of an agency or a freelancer, looking to expand
                  your client base and elevate your career.
                </div>
              </div>
            </div>

            <div className="w-full border-b-2 border-[#5a5a5a] ">
              <div
                className="flex items-center justify-between p-4 cursor-pointer md:p-6 rounded-3xl"
                onClick={toggleAnswer2}
              >
                <p className="text-lg sm:text-2xl md:text-2xl lg:text-3xl text-[#424530] font-josefin">
                  How does LAT generate leads?
                </p>
                <FontAwesomeIcon
                  icon={isOpen2 ? faXmark : faPlus}
                  color="[#424530]"
                  className="ml-2 text-2xl md:text-2xl lg:text-2xl"
                />
              </div>
              <div
                className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                  isOpen2 ? "max-h-40" : "max-h-0"
                }`}
              >
                <div className="p-4 md:p-6 mt-2 text-base sm:text-2xl md:text-2xl lg:text-2xl text-[#535353] rounded-3xl font-josefin">
                  LAT utilizes advanced analytics and targeted marketing
                  strategies to connect you with high-quality leads that match
                  your ideal client profile. Our leads are verified and tailored
                  to ensure the highest conversion rates.
                </div>
              </div>
            </div>

            <div className="w-full border-b-2 border-[#5a5a5a]">
              <div
                className="flex items-center justify-between p-4 cursor-pointer md:p-6 rounded-3xl"
                onClick={toggleAnswer3}
              >
                <p className="text-lg sm:text-2xl md:text-2xl lg:text-3xl text-[#424530] font-josefin">
                  What kind of support does LAT provide?
                </p>
                <FontAwesomeIcon
                  icon={isOpen3 ? faXmark : faPlus}
                  color="[#424530]"
                  className="ml-2 text-2xl md:text-2xl lg:text-2xl"
                />
              </div>
              <div
                className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                  isOpen3 ? "max-h-40" : "max-h-0"
                }`}
              >
                <div className="p-4 mb-10 md:mb-0 md:p-6 mt-2 text-base sm:text-2xl md:text-2xl lg:text-2xl text-[#535353] rounded-3xl font-josefin">
                  LAT offers comprehensive support including personalized
                  training, appointment scheduling, and ongoing client
                  engagement strategies. Our team is dedicated to helping you
                  succeed at every stage of your financial professional career.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  );
};

export default Home;
